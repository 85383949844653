import React from 'react';
import {useParams} from "react-router-dom";

const Card = props =>
{
    let { slug } = useParams();

    function card()
    {
        var cards = [];

        props.data.data.map(cat=>{
            cat.cards.map(card =>{
                cards.push(card);
            });
        })

        return cards.find(card => card.slug === slug);
    }

    return (
        <div style={style.wrapper}>
            <h2 style={style.titleWrapper}>
                <span style={style.title}>
                   {card().title[props.settings.language]}
                </span> 
                
                <audio style={{margin: '1rem 0'}} src={card().audio[props.settings.language]}></audio> 
                <a style={style.back}>←</a>
            </h2>
            
            <span style={style.body}>
                {card().body[props.settings.language]}
            </span>
            
            <p>
                <a href={card().pdf[props.settings.language]} style={style.pdf}>Download as PDF</a>
            </p>
        </div>
    )
}

const style = {
    wrapper: {
        width: '100%',
        display: 'block',
    },
    titleWrapper: {
        paddingBottom: 16,
        marginBottom: 16,
        borderBottom: '1 solid black',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    title: {
        flex: 1
    },
    back: {
        padding: '0 0 0 1em',
        fontSize: '1.6rem'
    },
    body: {
        fontSize: '1.6rem'
    },
    pdf: {
        backgroundColor: 'black',
        color: 'white',
        padding: 12,
        display: 'inline-block'
    }
}

export default Card;