import React, { Fragment, useState, useEffect, useRef } from "react";
import axios from "axios";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useParams,
  Redirect,
} from "react-router-dom";

import ReactHtmlParser from "react-html-parser";
import Vimeo from "@u-wave/react-vimeo";

import Icon from "./components/Icon";
import Footer from "./components/Footer";
import Header from "./components/Header";
import CustomAudio from "./components/CustomAudio";

import "./style.css";
import useWindowDimensions from "./useWindowDimensions";
import CacheBuster from "./components/CacheBuster";
import NotesPage from "./components/NotesPage";
import { useMemo } from "react";

const Home = ({
  settings,
  data,
  catsByLetter,
  searchResults,
  searchValue,
  onSearchChange,
  onSearchClicked,
  isFiltered,
}) => {
  const { lang } = useParams();
  let history = useHistory();
  const loadPage = (category) => {
    if (category.cards.length === 1) {
      const card = category.cards[0];
      history.push(`${lang}/card/${card.slug}`);
    } else {
      history.push(`${lang}/${category.slug}`);
    }
  };
  const [searchFocus, setSearchFocus] = useState(false);

  const onLanguageClick = (language) => {
    let current = history.location.pathname.replace(`${lang}`, language);
    history.replace(current);
  };

  return (
    <React.Fragment>
      {data.data.length === 0 ? (
        <div className="loadWrapper">
          <div className="loader"></div>
        </div>
      ) : (
        <React.Fragment>
          <Header
            settings={settings}
            onLanguageClick={(language) => onLanguageClick(language)}
          />

          <div class="fc-content">
            <div className="searchWrapper">
              {!isFiltered && (
                <p className="infoText">
                  Use the index or search bar below to choose a topic you would
                  like to talk about:
                </p>
              )}
              <input
                type="text"
                placeholder="Search"
                className={`searchBox ${searchFocus ? "searchFocused" : ""}`}
                value={searchValue}
                onChange={onSearchChange}
                onFocus={() => setSearchFocus(true)}
                onBlur={() => setSearchFocus(false)}
              />
            </div>

            <div class="fc-inner">
              {isFiltered ? (
                <ul className="catItemListWrapper filtered">
                  {searchResults(lang).map((card) => {
                    return card.translations ? (
                      <li
                        className="searchedTitle"
                        onClick={() => {
                          loadPage(card);
                          onSearchClicked();
                        }}
                      >
                        {card.translations[lang] || card.translations["en"]}
                      </li>
                    ) : (
                      <li
                        className="searchedTitle"
                        onClick={() => {
                          history.push(`${lang}/card/${card.slug}`);
                          onSearchClicked();
                        }}
                      >
                        {card.title[lang] || card.title["en"]}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                catsByLetter().map((letter) => (
                  <div class="fc-column">
                    <div class="fc-column-inner">
                      <h4 class="fc-header">{letter.letter}</h4>
                      <ul class="fc-list">
                        {letter.cats.map((cat) => (
                          <li
                            key={cat.translations[lang]}
                            class="fc-item"
                            onClick={() => loadPage(cat)}
                          >
                            {cat.translations[lang] || cat.translations["en"]}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          <Footer />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const Category = ({ settings, data }) => {
  let { lang, slug } = useParams();
  let history = useHistory();

  const [showAudio, setShowAudio] = useState(false);
  const [category, setCategory] = useState();
  const [audioPlaylist, setAudioPlaylist] = useState([]);

  useEffect(() => {
    setCategory(() => data.data.find((cat) => cat.slug === slug));
  }, [data, slug]);

  function loadCard(card) {
    if (card.nested) {
      history.push(`/${lang}/${card.slug}`, { nested: category.slug });
    } else if (history.location.state && history.location.state.nested) {
      history.push(`/${lang}/card/${card.slug}`, {
        nested: history.location.state.nested,
      });
    } else {
      history.push(`/${lang}/card/${card.slug}`);
    }
  }

  useEffect(() => {
    if (category) {
      let playlist = [category.titleAudio[lang]];
      if (category.cards.length > 0) {
        category.cards.map((card) => playlist.push(card.titleAudio[lang]));
      }
      setAudioPlaylist(playlist);
    }
  }, [category, lang]);

  const onLanguageClick = (language) => {
    let current = history.location.pathname.replace(`${lang}`, language);
    history.replace(current);
  };

  return (
    <React.Fragment>
      <Header
        settings={settings}
        onLanguageClick={(language) => onLanguageClick(language)}
      />
      <React.Fragment>
        {category && (
          <div>
            <div className="cardTitleWrapper">
              <div className="cardHeaderWrapper">
                <a
                  onClick={() =>
                    history.push(
                      history.location.state && history.location.state.nested
                        ? `/${lang}/${history.location.state.nested}`
                        : `/${lang}`
                    )
                  }
                  className="back"
                >
                  ←
                </a>
                <h1 className="cardTitle">{category.translations[lang]}</h1>
              </div>
              <div>
                {!showAudio && (
                  <img
                    src={`${process.env.PUBLIC_URL}/play-button.png`}
                    alt="play button"
                    className="toggle-button"
                    onClick={() => setShowAudio((current) => !current)}
                  />
                )}
                {showAudio && (
                  <CustomAudio
                    className="audioPlayer"
                    playlist={audioPlaylist}
                  />
                )}
              </div>
            </div>

            <ul className="categoryWrapper">
              {category.cards.map((card) => (
                <li onClick={() => loadCard(card)} className="cardItem">
                  {card.title[lang]}
                </li>
              ))}
            </ul>
          </div>
        )}
      </React.Fragment>
      <Footer />
    </React.Fragment>
  );
};

const Card = ({ settings, data }) => {
  const { width } = useWindowDimensions();

  let { lang, slug } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [_data, setData] = useState({ data: [] });
  const [card, setCard] = useState(null);
  const [showAudio, setShowAudio] = useState(false);
  const [viewingIndex, setViewingIndex] = useState(0);
  const [easyReadIndex, setEasyReadIndex] = useState(0);

  useEffect(() => {
    if (data.data.length > 0) {
      setData(data);
      let cards = [];
      data.data.map((cat) => {
        cat.cards.map((card) => {
          cards.push(card);
        });
      });
      let newCard = cards.find((card) => card.slug === slug);
      if (newCard) {
        newCard.body[lang] = newCard.body[lang].replace('href="/#', 'href="');
      }
      setCard(newCard);
      setIsLoading(false);
    } else {
      const fetchData = async () => {
        const result = await axios(process.env.REACT_APP_BASE_URL);

        setData(result.data);
        let cards = [];
        result.data.data.map((cat) => {
          cat.cards.map((card) => {
            cards.push(card);
          });
        });
        let newCard = cards.find((card) => card.slug === slug);
        if (newCard) {
          newCard.body[lang] = newCard.body[lang].replace('href="/#', 'href="');
        }
        setCard(newCard);
        setIsLoading(false);
      };

      fetchData();
    }
  }, [data, lang, slug]);

  const iconParse = /(<.*?>)/g;

  const parseEasyRead = (text) => {
    let parsed = text.replaceAll(iconParse, (match) => iconLookup(match));
    return parsed;
  };

  const onLanguageClick = (language) => {
    let current = history.location.pathname.replace(`${lang}`, language);
    history.replace(current);
  };

  const backPush = () => {
    if (history.location.state && history.location.state.nested) {
      history.push(`/${lang}/${card.backLink}`, {
        nested: history.location.state.nested,
      });
    } else if (card.backLink === "/") {
      history.push(`/${lang}`);
    } else {
      history.push(`/${lang}/${card.backLink}`);
    }
  };

  const iconLookup = (match) => {
    let icon = "";
    let iconAltText = "";
    switch (match) {
      case "<3306.png>":
        icon = "./assets/easyRead/3306.png";
        break;
      case "<1852.png>":
        icon = "./assets/easyRead/1852.png";
        break;
      case "<19008.png>":
        icon = "./assets/easyRead/19008.png";
        break;
      case "<4257.png>":
        icon = "./assets/easyRead/4257.png";
        break;
      case "<2575.png>":
        icon = "./assets/easyRead/2575.png";
        break;
      case "<3650.png>":
        icon = "./assets/easyRead/3650.png";
        break;
      case "<2400.png>":
        icon = "./assets/easyRead/2400.png";
        break;
      case "<16841.png>":
        icon = "./assets/easyRead/16841.png";
        break;
      case "<5409.png>":
        icon = "./assets/easyRead/5409.png";
        break;
      case "<6898.png>":
        icon = "./assets/easyRead/6898.png";
        break;
      case "<2432.png>":
        icon = "./assets/easyRead/2432.png";
        break;
      case "<6890.png>":
        icon = "./assets/easyRead/6890.png";
        break;
      case "<6544.png>":
        icon = "./assets/easyRead/6544.png";
        break;
      case "<5912.png>":
        icon = "./assets/easyRead/5912.png";
        break;
      case "<2223.png>":
        icon = "./assets/easyRead/2223.png";
        break;
      case "<3165.png>":
        icon = "./assets/easyRead/3165.png";
        break;
      case "<6820.png>":
        icon = "./assets/easyRead/6820.png";
        break;
      case "<2164.png>":
        icon = "./assets/easyRead/2164.png";
        break;
      case "<3669.png>":
        icon = "./assets/easyRead/3669.png";
        break;
      case "<3517.png>":
        icon = "./assets/easyRead/3517.png";
        break;
      case "<14859.png>":
        icon = "./assets/easyRead/14859.png";
        break;
      case "<2046.png>":
        icon = "./assets/easyRead/2046.png";
        break;
      case "<6357.png>":
        icon = "./assets/easyRead/6357.png";
        break;
      case "<13661.png>":
        icon = "./assets/easyRead/13661.png";
        break;
      case "<18792.png>":
        icon = "./assets/easyRead/18792.png";
        break;
      case "<2403.png>":
        icon = "./assets/easyRead/2403.png";
        break;
      case "<16844.png>":
        icon = "./assets/easyRead/16844.png";
        break;
      case "<1851.png>":
        icon = "./assets/easyRead/1851.png";
        break;
      case "<9359.png>":
        icon = "./assets/easyRead/9359.png";
        break;
      case "<15092.png>":
        icon = "./assets/easyRead/15092.png";
        break;
      case "<635.png>":
        icon = "./assets/easyRead/635.png";
        break;
      case "<10392.png>":
        icon = "./assets/easyRead/10392.png";
        break;
      case "<13248.png>":
        icon = "./assets/easyRead/13248.png";
        break;
      case "<4271.png>":
        icon = "./assets/easyRead/4271.png";
        break;
      case "<2624.png>":
        icon = "./assets/easyRead/2624.png";
        break;
      case "<10695.png>":
        icon = "./assets/easyRead/10695.png";
        break;
      case "<4006.png>":
        icon = "./assets/easyRead/4006.png";
        break;
      case "<3492.png>":
        icon = "./assets/easyRead/3492.png";
        break;
      case "<5940.png>":
        icon = "./assets/easyRead/5940.png";
        break;
      case "<12555.png>":
        icon = "./assets/easyRead/12555.png";
        break;
      case "<6904.png>":
        icon = "./assets/easyRead/6904.png";
        break;
      case "<17015.png>":
        icon = "./assets/easyRead/17015.png";
        break;
      case "<3508.png>":
        icon = "./assets/easyRead/3508.png";
        break;
      case "<4533.png>":
        icon = "./assets/easyRead/4533.png";
        break;
      case "<2616.png>":
        icon = "./assets/easyRead/2616.png";
        break;
      case "<9847.png>":
        icon = "./assets/easyRead/9847.png";
        break;
      case "<3514.png>":
        icon = "./assets/easyRead/3514.png";
        break;
      case "<5415.png>":
        icon = "./assets/easyRead/5415.png";
        break;
      case "<3609.png>":
        icon = "./assets/easyRead/3609.png";
        break;
      default:
        icon = "./CardMedic-Logo-r.png";
        iconAltText = "Card Medic logo";
    }
    return `<img src=${require(`${icon}`)} alt=${iconAltText} class="easyRead-icon" />`;
  };

  return (
    <React.Fragment>
      {!isLoading && (
        <Header
          settings={settings}
          onLanguageClick={(language) => onLanguageClick(language)}
        />
      )}
      {isLoading ? (
        <div className="loadWrapper">
          <div className="loader"></div>
        </div>
      ) : (
        <React.Fragment>
          <div className="titleWrapper">
            <div className="cardHeaderWrapper">
              <a onClick={() => backPush()} className="back">
                ←
              </a>
              <h1 className="title">{card.title[lang]}</h1>
            </div>
            <div className="cardIconWrapper">
              {card.bsl && (
                <Icon
                  subText="BSL"
                  icon="fa-sign-language"
                  style={{ marginRight: width > 767 ? "2.5rem" : "1rem" }}
                  color={"#007ac3"}
                  onClick={() =>
                    viewingIndex === 1 ? setViewingIndex(0) : setViewingIndex(1)
                  }
                />
              )}
              {card.cardEasyRead && (
                <Icon
                  subText="Easy Read"
                  icon="fa-book-reader"
                  color={"#007ac3"}
                  onClick={() =>
                    viewingIndex === 2 ? setViewingIndex(0) : setViewingIndex(2)
                  }
                />
              )}

              {!showAudio && (
                <img
                  src={`${process.env.PUBLIC_URL}/play-button.png`}
                  alt="play button"
                  className="toggle-button iconWrapper"
                  onClick={() => setShowAudio((current) => !current)}
                />
              )}
              {showAudio && (
                <CustomAudio
                  className="audioPlayer"
                  playlist={[card.titleAudio[lang], card.bodyAudio[lang]]}
                />
              )}
            </div>
          </div>

          <div className="cardBody-inner">
            <div className="bslWrapper">
              {viewingIndex === 1 && (
                <Vimeo
                  autoplay={false}
                  style={{ margin: 30 }}
                  width={width > 767 ? 640 : 375}
                  height={width > 767 ? 360 : 210}
                  video={card.bsl}
                />
              )}
            </div>
            {viewingIndex === 0 && (
              <p className="cardBodyText">{ReactHtmlParser(card.body[lang])}</p>
            )}
            {viewingIndex === 2 && (
              <div className="easyReadWrapper">
                {easyReadIndex > 0 && (
                  <Icon
                    className="prev"
                    icon="fa-arrow-left"
                    onClick={() => setEasyReadIndex((current) => current - 1)}
                  />
                )}
                <div
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    textAlign: "center",
                    alignItems: "flex-start",
                    justifyContent: "space-around",
                  }}
                >
                  {ReactHtmlParser(
                    parseEasyRead(card.cardEasyRead[easyReadIndex])
                  )}
                </div>
                {easyReadIndex < card.cardEasyRead.length - 1 && (
                  <Icon
                    className="next"
                    icon="fa-arrow-right"
                    onClick={() => setEasyReadIndex((current) => current + 1)}
                  />
                )}
              </div>
            )}
          </div>
        </React.Fragment>
      )}
      {!isLoading && <Footer />}
    </React.Fragment>
  );
};

function App() {
  const [data, setData] = useState({ data: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState(
    // "https://staging.cardmedic.com/api/categories"
    process.env.REACT_APP_BASE_URL
  );
  const [settings, setSettings] = useState({
    dark_mode: false,
    fontSize: 2,
    language: "en",
    name: "",
    occupation: "",
    location: "",
  });
  const [searchText, setSearchText] = useState("");
  const [allCards, setAllCards] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await axios(url);

      setData(result.data);
      setIsLoading(false);
    };

    fetchData();
  }, [url]);

  useEffect(() => {
    let sortedCat = data.data.sort(compare);
    let sortedCards = [];
    sortedCat.map((cat) => {
      cat.cards.map((i) => sortedCards.push(i));
    });
    setAllCards(sortedCards);
  }, [data.data]);

  const allCategories = (lang) =>
    catsByLetter().map((letter) => letter.cats.map((cat) => cat));

  function compare(a, b) {
    // Use toUpperCase() to ignore character casing
    let comparison = 0;

    if (a.order < b.order) {
      comparison = -1;
    } else if (a.order > b.order) {
      comparison = 1;
    } else if (a.order === b.order) {
      const slugA = a.slug.toUpperCase();
      const slugB = b.slug.toUpperCase();

      if (slugA > slugB) {
        comparison = 1;
      } else if (slugA < slugB) {
        comparison = -1;
      }
    }

    return comparison;
  }

  function searchResults(lang) {
    let all = allCategories(lang).flat();

    let results = allCards.filter((card) => {
      let body;
      let title;
      if (!!card.title) {
        title = card.title[lang].toUpperCase();
      }
      if (!!card.body) {
        body = card.body[lang].toUpperCase();
      }
      if (title || body) {
        return (
          title.includes(searchText.toUpperCase()) ||
          (body && body.includes(searchText.toUpperCase()))
        );
      } else {
        return false;
      }
    });
    let myArray = all.concat(results).sort((a, b) => {
      let first;
      let second;
      if (a.translations) {
        first = a.translations[lang];
      } else {
        first = a.title[lang];
      }
      if (b.translations) {
        second = b.translations[lang];
      } else {
        second = b.title[lang];
      }
      return first.toUpperCase() < second.toUpperCase()
        ? -1
        : first.toUpperCase() > second.toUpperCase()
        ? 1
        : 0;
    });
    const final = [];
    const map = new Map();
    for (const item of myArray) {
      if (!map.has(item.slug)) {
        map.set(item.slug, true);
        final.push(item);
      }
    }
    return final;
  }

  function catsByLetter() {
    let sortedCat = data.data.sort(compare);
    if (searchText.length >= 2) {
      sortedCat = sortedCat.filter((item) => {
        return item.title.toUpperCase().includes(searchText.toUpperCase());
      });
    }

    let alphabet = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "g",
      "h",
      "i",
      "j",
      "k",
      "l",
      "m",
      "n",
      "o",
      "p",
      "q",
      "r",
      "s",
      "t",
      "u",
      "v",
      "w",
      "x",
      "y",
      "z",
    ];

    alphabet = alphabet.map((a) => {
      return {
        letter: a,
        cats: sortedCat.filter((cat) => cat.slug[0] === a),
      };
    });
    return alphabet;
  }

  const searchHandler = (event) => {
    setSearchText(event.target.value);
  };

  const onSearchClicked = () => {
    setSearchText("");
  };

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }
        return (
          <Router>
            <Switch>
              <Redirect exact from="/" to="/en" />
              <Route
                exact
                path="/notes"
                render={() => <NotesPage settings={settings} />}
              />
              <Route
                path="/:lang/card/:slug"
                render={() => <Card settings={settings} data={data} />}
              />
              <Route
                path="/:lang/:slug"
                render={() => <Category settings={settings} data={data} />}
              />
              <Route
                path="/:lang"
                render={() => (
                  <Home
                    key={settings}
                    settings={settings}
                    data={data}
                    catsByLetter={catsByLetter}
                    onSearchChange={searchHandler}
                    searchResults={searchResults}
                    searchValue={searchText}
                    isFiltered={searchText.length >= 2}
                    onSearchClicked={onSearchClicked}
                  />
                )}
              />
            </Switch>
            );
          </Router>
        );
      }}
    </CacheBuster>
  );
}

export default App;
