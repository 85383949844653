import React, { useEffect, useRef, useState } from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useParams } from "react-router-dom";

const CustomAudio = (props) => {
  const [audioIndex, setAudioIndex] = useState(0);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const audioRef = useRef(null);
  const { lang } = useParams();

  const onAudioEnded = () => {
    if (audioIndex === props.playlist.length - 1) {
      setAudioIndex(0);
      audioRef.current.audio.current.pause();
    } else {
      setAudioIndex((current) => current + 1);
      audioRef.current.audio.current.play();
    }
  };

  useEffect(() => {
    setAudioIndex(0);
  }, [lang]);

  const togglePlaybackSpeed = () => {
    switch (playbackSpeed) {
      case 1:
        setPlaybackSpeed(2);
        audioRef.current.audio.current.playbackRate = 1.25;
        break;
      case 2:
        setPlaybackSpeed(0);
        audioRef.current.audio.current.playbackRate = 0.75;
        break;
      default:
        setPlaybackSpeed(1);
        audioRef.current.audio.current.playbackRate = 1;
        break;
    }
  };

  useEffect(() => {
    console.log("audio ref", audioRef);
  }, [audioRef]);

  return (
    <AudioPlayer
      className={props.className}
      src={props.playlist[audioIndex]}
      ref={audioRef}
      style={{
        maxWidth: "300px",
        width: "fit-content",
      }}
      autoPlayAfterSrcChange={false}
      onEnded={() => onAudioEnded()}
      showDownloadProgress={false}
      showFilledProgress={false}
      customControlsSection={[
        RHAP_UI.MAIN_CONTROLS,
        <i
          class={`fas playbackSpeedIcon fa-tachometer-alt-${
            playbackSpeed === 0
              ? "slowest"
              : playbackSpeed === 1
              ? "average"
              : playbackSpeed === 2
              ? "fastest"
              : "average"
          }`}
          style={{
            color: "#868686",
            fontSize: 24,
            marginLeft: "5px",
          }}
          onClick={() => togglePlaybackSpeed()}
        ></i>,
      ]}
      customProgressBarSection={[
        <div style={{ flex: 1 }}></div>,
        RHAP_UI.CURRENT_TIME,
        <div
          style={{
            flex: 1,
          }}
        ></div>,
      ]}
      layout={"horizontal"}
      listenInterval={500}
    />
  );
};

export default CustomAudio;
