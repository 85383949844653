import React from 'react';
import {useHistory} from 'react-router-dom';


const Test = props =>
{
    let history = useHistory();

    function handleClick() {    
        history.push("/");
      }
    

    return (
        <div>
            <h2 onClick={()=>handleClick()}>Test page</h2>
        </div>
    )
}

export default Test;