import React, { useState } from "react";
import Header from "./Header";
import "../style.css";
const AWS = require("aws-sdk");
AWS.config.credentials = new AWS.Credentials(
  "AKIA5VDIXIOE2JZ4GQWI",
  "TDRFkul+K/ImUcIkwbMpUBt/pm5wIxShlo7gwLme"
);
AWS.config.update({ region: "eu-west-2" });
const translate = new AWS.Translate();
const polly = new AWS.Polly();

const languages = [
  "ar",
  //"bg",
  "de",
  "en",
  //"en-US",
  "es",
  //"fa",
  "fr",
  "hi",
  //"hu",
  "it",
  //"ja",
  "nl",
  "pa",
  "pl",
  "pt",
  "zh",
];

const voices = {
  "es": "Penelope",
  "pt": "Vitoria",
  "de": "Vicki",
  "en": "Emma",
  "fr": "Celine",
  "ar": "Zeina",
  "ru": "Tatyana",
  "hi": "Aditi",
  "it": "Carla",
  "nl": "Lotte",
  "pl": "Ewa",
  "zh": "Zhiyu",
};

const NotesPage = ({ settings }) => {
  const [inputText, setInputText] = useState();
  const [sourceLanguage, setSourceLanguage] = useState("auto");
  const [targetLanguage, setTargetLanguage] = useState();

  const translateHandler = () => {
    if (!inputText) {
      alert("Input text cannot be empty.");
      return;
    }
    translate.translateText(
      {
        Text: inputText,
        SourceLanguageCode: sourceLanguage,
        TargetLanguageCode: targetLanguage,
      },
      (err, data) => {
        if (err) {
          console.log(err, err.stack);
          alert("Error calling Amazong Translate. " + err.message);
          return;
        }
        if (data) {
          setInputText(data.TranslatedText);
        }
      }
    );
  };

  const pollyHandler = () => {
    const voice = voices[targetLanguage];
    polly.synthesizeSpeech(
      {
        OutputFormat: "mp3",
        SampleRate: "8000",
        Text: inputText,
        TextType: "text",
        VoiceId: voice,
      },
      (err, data) => {
        if (err) {
          console.log(err, err.stack);
          alert("Error " + err.message);
          return;
        }
        if (data) {
          let uInt8Array = new Uint8Array(data.AudioStream);
          let arrayBuffer = uInt8Array.buffer;
          let blob = new Blob([arrayBuffer]);
          let url = URL.createObjectURL(blob);
          console.log("url", url);
          let audioElement = new Audio([url]);
          audioElement.play();
        }
      }
    );
  };

  return (
    <div>
      <Header settings={settings} />
      <React.Fragment>
        <div className="notesPageWrapper">
          <div className="notesPageInner">
            <textarea
              placeholder="Write your own notes or questions here"
              rows="10"
              type="text"
              id="main"
              name="main"
              value={inputText}
              className="notesInput"
              onChange={(event) => setInputText(event.target.value)}
            ></textarea>

            <div className="notesDropContainer">
              <div className="notesDropLeft">
                <label for="sourceLanguage">Source language</label>
              </div>
              <div className="notesDropRight">
                <select
                  className="notesDropdown"
                  name="sourceLanguage"
                  id="sourceLanguage"
                  onChange={(event) => setSourceLanguage(event.target.value)}
                >
                  <option value="auto">Auto</option>
                  {languages.map((lang) => (
                    <option value={lang}>{lang}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="notesDropContainer">
              <div className="notesDropLeft">
                <label for="sourceLanguage">Target language</label>
              </div>
              <div className="notesDropRight">
                <select
                  className="notesDropdown"
                  name="sourceLanguage"
                  id="sourceLanguage"
                  onChange={(event) => setTargetLanguage(event.target.value)}
                >
                  <option value="null"></option>
                  {languages.map((lang) => (
                    <option value={lang}>{lang}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="notesButtonWrapper">
              <button
                disabled={!inputText || !targetLanguage}
                className={`notesButton ${
                  !inputText || !targetLanguage ? "notesButtonDisabled" : ""
                }`}
                type="button"
                onClick={translateHandler}
              >
                Translate
              </button>
              <button
                disabled={!inputText || !targetLanguage}
                className={`notesButton ${
                  !inputText || !targetLanguage ? "notesButtonDisabled" : ""
                }`}
                type="button"
                onClick={pollyHandler}
              >
                Read Aloud
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default NotesPage;
