import React from "react";

const Footer = (props) => {
  return (
    <div class="footer-wrap">
      <div class="footer-inner">
        <div class="footer-column">
          <a href="https://www.cardmedic.com/">
            <img
              src={require("../assets/CardMedic-Logo.png")}
              class="footer-cm-logo"
              alt="CardMedic Logo"
            />
          </a>

          <div class="copyright-text">Copyright &copy; 2020 CardMedic&reg;</div>

          <div class="badge-icon">
            <a
              href="https://twitter.com/cardmedic"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fab fa-twitter twitterIcon"></i>
            </a>
          </div>
        </div>

        <div class="footer-column">
          <div class="footer-title">Download our apps</div>

          <div>
            <a
              href="https://apps.apple.com/us/app/cardmedic/id1510011459?mt=8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../assets/Apple-Store.png")}
                alt="Apple App Store"
              />
            </a>
          </div>

          <div>
            <a
              href="https://play.google.com/store/apps/details?id=com.cardmedic.app&amp;hl=en_US&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("../assets/Google-Play.png")}
                alt="Google Play Store"
              />
            </a>
          </div>
        </div>

        <div class="footer-column">
          <div class="footer-title">Menu</div>
          <div class="footer-menu">
            <a href="https://www.cardmedic.com/about">About</a>
          </div>
          <div class="footer-menu">
            <a href="https://www.cardmedic.com/contact">Contact</a>
          </div>
          <div class="footer-menu">
            <a href="https://www.cardmedic.com/legal">Legal</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
