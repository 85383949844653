import React from 'react';
import {useParams,useHistory} from "react-router-dom";

const Category = props =>
{
    let { slug } = useParams();
    let history = useHistory();

    function category() {
        return props.data.data.find(cat => cat.slug === slug)
    }

    function loadCard(card)
    {
        history.push(`/card/${card.slug}`);
    }

    return (
        <div style={style.wrapper}>
            <h4 style={style.titleWrapper}>
                {category().translations[props.settings.language]}<a onClick={()=>history.goBack()} style={style.back}>←</a>
            </h4>

            <ul style={style.categoryWrapper}>
                {category().cards.map(card=>
                    <li onClick={()=>loadCard(card)} style={style.cardItem}>
                        {card.title[props.settings.language]}
                    </li>
                )}
            </ul>
        </div>
    )
}

const style = {
    wrapper: {
        width: '100%',
        display: 'block',
    },
    titleWrapper: {
        textTransform: 'uppercase', 
        paddingBottom: 16, 
        marginBottom: 16,
        borderBottom: '1px solid black',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between'
    },
    back: {
        padding: '0 0 0 1em',
        fontSize: '1.6rem',
        cursor: 'pointer',
    },
    categoryWrapper: {
        padding: 0,
        margin: 0,
        listStyle: 'none'
    },
    cardItem: {
        cursor: 'pointer',
        padding: '8px 0',
        fontSize: '1.6rem'
    }
}

export default Category;