import React from "react";
import { Link, useParams } from "react-router-dom";
import useWindowDimensions from "../useWindowDimensions";
import LanguageSelectDropdown from "./LanguageSelectDropdown";

const Header = (props) => {
  const { lang } = useParams();
  const { width } = useWindowDimensions();
  return (
    <div class="header-wrap">
      <div class="header-inner">
        <Link to={`/${lang}`}>
          <div class="header-left">
            {width > 767 ? (
              <img
                src={require("../assets/CardMedic-Logo-r.png")}
                alt="CardMedic Logo"
              />
            ) : (
              <img
                src={`${process.env.PUBLIC_URL}/CardMedicLogo.png`}
                alt="CardMedic Logo"
              />
            )}
          </div>
        </Link>
        <div class="header-right desktop">
          {props.onLanguageClick && (
            <LanguageSelectDropdown
              settings={props.settings}
              onLanguageClick={props.onLanguageClick}
            />
          )}
          <a href="https://www.cardmedic.com/">Home</a>
          <a href="https://www.cardmedic.com/contact">Help</a>
          <a href={`/notes`}>Notes</a>
        </div>
        <div class="header-right mobile">
          {props.onLanguageClick && (
            <LanguageSelectDropdown
              settings={props.settings}
              onLanguageClick={props.onLanguageClick}
            />
          )}
          <a href="https://www.cardmedic.com/contact">
            <div class="badge-icon">
              <i class="fas fa-question"></i>
            </div>
          </a>
          <a href="https://www.cardmedic.com/">
            <div class="badge-icon">
              <i class="fas fa-globe-europe"></i>
            </div>
          </a>
          <a href={`/notes`}>
            <div class="badge-icon">
              <i class="fas fa-sticky-note"></i>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
