import React, { useState } from "react";
import { useParams } from "react-router-dom";

const LanguageSelectDropdown = (props) => {
  const { lang } = useParams();
  const { onLanguageClick } = props;
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);

  const toggleLanguageDropdown = () => {
    setShowLanguageDropdown((current) => !current);
  };

  const languages = [
    "ar",
    //"bg",
    "de",
    "en",
    "en-US",
    "es",
    //"fa",
    "fr",
    "hi",
    //"hu",
    "it",
    //"ja",
    "nl",
    "pa",
    "pl",
    "pt",
    "zh",
  ];

  return (
    <div className="languageSelectWrapper" onClick={toggleLanguageDropdown}>
      <div className="languageCurrent">
        <img
          src={`${process.env.PUBLIC_URL}/flags/${lang}.png`}
          alt={`${lang} flag`}
          className="flagIcon"
        />
        <p className="uppercase">{lang}</p>
        <img
          src={`${process.env.PUBLIC_URL}/chevron-right.png`}
          alt="Chevron right"
          className={`chevron ${
            showLanguageDropdown ? "chevronRotateClockwise" : ""
          }`}
        />
      </div>

      {showLanguageDropdown && (
        <div className="languageScroll">
          {languages.map((l) => (
            <div className="languageRow" onClick={() => onLanguageClick(l)}>
              <img
                src={`${process.env.PUBLIC_URL}/flags/${l}.png`}
                alt={`${l} flag`}
                className="flagIcon"
              />
              <p className="uppercase">{l}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelectDropdown;
